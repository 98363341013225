import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: '/',
    redirect: '/check'
  },
  {
    path: '/check',
    component: () => import("../views/Check.vue")
  },
  {
    path: '/banner',
    component: () => import("../views/pc/user/Banner.vue"),
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import("../views/pc/user/Home.vue"),
        redirect: '/article',
        children: [
          {
            path: '/article',
            component: () => import("../views/pc/user/Article.vue")
          }, {
            path: '/message',
            component: () => import("../views/pc/user/Message.vue")
          }
        ]
      }, {
        path: '/p',
        component: () => import("../views/pc/user/ArticleDetail.vue")
      }, {
        path: '/create',
        component: () => import("../views/pc/manage/Create.vue")
      }, {
        path: '/404',
        component: () => import("../components/404.vue"),
      }, {
        path: '/manage_article',
        component: () => import("../views/pc/manage/Article.vue")
      }, {
        path: '/manage_message',
        component: () => import("../views/pc/manage/Message.vue")
      }, {
        path: '/manage_comment_article',
        component: () => import("../views/pc/manage/CommentArticle.vue")
      }, {
        path: '/comment_edit',
        component: () => import("../views/pc/manage/CommentEdit.vue")
      }, {
        path: '/manage_comment',
        component: () => import("../views/pc/manage/Comment.vue")
      }, {
        path: '/wechat',
        component: () => import("../views/pc/user/Wechat.vue")
      }, {
        path: '/qq',
        component: () => import("../views/pc/user/QQ.vue")
      }, {
        path: '/user',
        component: () => import("../views/pc/manage/Config.vue")
      }
    ]
  },
  {
    path: '/m-banner',
    component: () => import("../views/mobile/user/Banner.vue"),
    redirect: '/m-home',
    children: [
      {
        path: '/m-home',
        component: () => import("../views/mobile/user/Home.vue"),
        redirect: '/m-article',
        children: [
          {
            path: '/m-article',
            component: () => import("../views/mobile/user/Article.vue")
          }, {
            path: '/m-message',
            component: () => import("../views/mobile/user/Message.vue")
          }, {
            path: '/m-like',
            component: () => import("../views/mobile/user/Like.vue")
          }, {
            path: '/m-p',
            component: () => import("../views/mobile/user/ArticleDetail.vue")
          }
        ]
      }, {
        path: '/m-create',
        component: () => import("../views/mobile/manage/Create.vue")
      }, {
        path: '/m-404',
        component: () => import("../components/m404.vue"),
      }, {
        path: '/m-manage_article',
        component: () => import("../views/mobile/manage/Article.vue")
      }, {
        path: '/m-manage_message',
        component: () => import("../views/mobile/manage/Message.vue")
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router