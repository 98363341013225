import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'font-awesome/css/font-awesome.min.css'
import { pdate, pdatePro, getUUID, copyContent, text2Doc, updateElapsedTime, detectDeviceType } from '@/utils/toolsUtil.js'
import store from './store';

// highlight 的样式，依赖包，组件
// import 'highlight.js/styles/atom-one-dark.css'
// import 'highlight.js/lib/common'
// import hljs from '@highlightjs/vue-plugin'

import router from '@/router'

import showMsgBox from '@/utils/messageBoxUtil.js'



/**
 * 检查token是否过期
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  const pathsToCheck = [
    'banner', 'm-banner', 'home', 'm-home', 'article', 'm-article',
    'message', 'm-message', 'like', 'm-like', 'p', 'm-p', 'm-create', 'create', 'manage_article', 'm-manage_article', 'manage_message', 'm-manage_message'
  ];

  // 获取当前路径中的 yyy 部分
  const currentPath = to.path.split('/')[1];

  // 仅当当前路径匹配时才进行检查
  if (pathsToCheck.includes(currentPath)) {
    const result = detectDeviceType();

    if (result === 1) {
      if (!currentPath.startsWith('m-')) {
        const newPath = to.path.replace(`/${currentPath}`, `/m-${currentPath}`);
        next({ path: newPath });
      } else {
        next();
      }
    } else if (result === 2) {
      if (currentPath.startsWith('m-')) {
        const newPath = to.path.replace(`/${currentPath}`, `/${currentPath.slice(2)}`);
        next({ path: newPath }); 
      } else {
        next();
      }
    }
  } else {
    next();
  }
})


const app = createApp(App)

// 定义filter方法
app.config.globalProperties.$utils = {
  pdate: pdate,
  pdatePro: pdatePro,
  getUUID: getUUID,
  copyContent: copyContent,
  text2Doc: text2Doc,
  updateElapsedTime: updateElapsedTime,
  detectDeviceType: detectDeviceType
}

// 定义全局变量
app.config.globalProperties.$msg = showMsgBox

// app.use(router).use(ElementPlus, {locale}).use(hljs ).mount('#app')
app.use(router).use(store).use(ElementPlus, {locale}).mount('#app')