/**
 * 维护一个messagebo列表，在入口文件中注册
 */

import { ElMessage } from 'element-plus'

const statusArr = {
  // 内容填写
  'SELECTION_NULL': {
    type: 'warning',
    message: '请选择内容'
  }, 
  'INPUT_NULL': {
    type: 'warning',
    message: '内容不能为空'
  },
  // 请求结果
  'REQUEST_SUCCESS': {
    type: 'success',
    message: '操作成功'
  },
  // 参数错误
  'REQUEST_CONFIG_ERROR': {
    type: 'warning',
    message: '请求参数有误'
  },
  // 输入格式有误
  'INPUT_CONTENT_NOT_RIGHT': {
    type: 'warning',
    message: '输入内容格式有误'
  }
}

export default function showMsgBox(status) {
  ElMessage({
    type: statusArr[status].type,
    message: statusArr[status].message,
    grouping: true,
    duration: statusArr[status].duration ? statusArr[status].duration : 3000
  })
}